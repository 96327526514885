<template>
  <div class="page-wrapper">
    <div class="title-content">
      <BreadCrumb>
        <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
        <!-- <el-breadcrumb-item :to="{ name: 'test-hub' }">检测中心</el-breadcrumb-item> -->

        <el-breadcrumb-item> 印花税测算</el-breadcrumb-item>
      </BreadCrumb>
      <div class="main-top">
        <MainHeader>
          <template slot="title">数据上传</template>
        </MainHeader>
        <SaSteps :steps="steps" :active-index="stepActive"></SaSteps>
        <div class="placeholder"></div>
      </div>
    </div>
    <div class="section-box">
      <div class="title">
        一.请按照数据模板的格式准备要导入的数据。<a
        href="https://shuian-file.oss-cn-hangzhou.aliyuncs.com/user/%E5%90%88%E5%90%8C%E5%8F%B0%E8%B4%A6%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
        target="_blank" class="tips">点击下载《合同台账导入模板》</a>
      </div>
      <div class="guide">
        <div>①表格首行请严格按照模板，便于系统识别。</div>

        <div>②请确保上传表格中中合同金额为“数值”格式，且单元格格式为“数值”；合同签订日期为“日期”格式。</div>

        <div>③为便于统计，请统一“合同金额”货币单位。</div>

        <div>④支持导入多个文件，但表名不能相同。</div>
      </div>
    </div>
    <div class="section-box">
      <div class="title">
        二.请选择需要导入的文件
      </div>
      <GeneralUploader :upload-url="uploadUrl" param-name="files"
                       @complete="handleUploaderComplete">
        <div class="dz-message">
          <img class="drog_img" src="@/assets/test/uploader@2x.png" alt/>
          <div class="drog_text">
            <span class="drog_text-blue">点击上传, 或将文件拖拽到此处</span>
          </div>
          <span class="acceptable_text"
          >支持：Excel/HTML/文件夹</span
          >
        </div>
      </GeneralUploader>
    </div>
    <div class="section-box">
      <div class="title">
        三.数据预览
        <div class="tips">请查看是否存在匹配错位或行次不足的情况，如有异常请清除数据后重新上传。</div>
      </div>
      <el-table
        :data="previewTable"
        stripe
        style="width: 100%">
        <el-table-column
          prop="index"
          label="行号"
          width="180">
        </el-table-column>
        <el-table-column
          prop="taxItemName"
          label="合同名称"
          width="180">
        </el-table-column>
        <el-table-column
          prop="contractNo"
          label="合同编号">
        </el-table-column>
        <el-table-column
          prop="contractSignDate"
          label="合同签订日期">
        </el-table-column>
        <el-table-column
          prop="contractAmount"
          label="合同金额">
        </el-table-column>
        <el-table-column
          prop="companyName"
          label="对方单位名称">
        </el-table-column>
        <el-table-column
          prop="companyType"
          label="企业分类">
        </el-table-column>
        <el-table-column
          prop="amountTax"
          label="已纳税额">
        </el-table-column>
      </el-table>

    </div>
    <SaFooter>
      <button
        class="btn btn-purge-data"
        @click="handleBtnPurgeDataClicker"
      >
        清除数据
      </button>
      <button
        class="btn btn-primary btn-next"
        @click="handleBtnNextClick"
      >
        下一步
      </button>
    </SaFooter>
  </div>
</template>

<script>
import BreadCrumb from "../../../components/BreadCrumb";
import MainHeader from "../../components/MainHeader";
import SaFooter from "../../Test/UploadTable/components/SaFooter";
import SaSteps from "../../components/SaSteps";
import GeneralUploader from "../../components/GeneralUploader";
import {clearContData, getContDetailList, uploadContExcelByStamp} from "../../../api/yinhuashui";

export default {
  name: "StampTax",
  props: {
    programId: String
  },
  components: {
    SaFooter,
    BreadCrumb,
    MainHeader,
    SaSteps,
    GeneralUploader
  },
  mounted() {
    this.loadTableData()
  },
  data() {
    return {
      previewTable: [],
      steps: ['基础信息', '合同台账上传', '印花税申报表上传', '提交成功'],
      stepActive: 1,
      form: {
        companyName: '',
        nsrsbh: '',

      },
      table: []
    }
  },
  methods: {
    handleUploaderComplete(files) {
      const total = files.length;

      let success = 0;
      for (const fileGrp of files) {
        for (const file of fileGrp) {
          if (file.status == "success") {
            success++
          }
        }
      }

      const failure = total - success;
      let msg = `共上传${total}个文件,成功${success}个`;
      if (failure > 0) {
        msg += `,失败${failure}个`
      }
      this.$alert(msg, "提示", {
        confirmButtonText: "确定",
        type: "info"
      });

      this.loadTableData();

    },
    //载入合同台帐明细 前后5条
    async loadTableData() {
      const res = await getContDetailList({programId: this.programId})
      this.previewTable = res.data
    },
    handleBtnNextClick() {
      this.$router.push({
        name: 'stampTax-yinHuashui'
      })
    },
    //清除数据
    //清空所有已经上传的报表
    handleBtnPurgeDataClicker() {
      this.$confirm(`所有文件将被删除, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const result = await clearContData({
          programId: this.programId
        });

        if (result.code == 200) {
          this.$emit("listChanged");
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.loadTableData();
        } else {
          this.$message.error(result.message);
        }
      });
    },
  },
  computed: {

    uploadUrl() {
      return uploadContExcelByStamp(this.programId)
    },
  }
}
</script>

<style lang="stylus" scoped>
.page-wrapper {
  padding-bottom: 84px;

  .title-content {
    // width: 1260px;
    height: 96px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
  }

  .main-top {
    display: flex;
    justify-content: space-between;
  }

  .section-box {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);

    .title {
      color: rgba(0, 0, 0, 0.8);
      line-height: 54px;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #eeeeee;

      .tips {
        display inline-block;
        font-size: 14px;
        color: #f5222d;
        margin-left: 16px;
      }
    }

    .guide {
      margin-top 20px
      line-height 2

    }

  }

  .btn-purge-data {
    color: #ff6464;
  }

  .table {
    border-collapse: collapse;
    box-shadow: 0px -1px 0px 0px #EEEEEE;
    background #fff;
    width 100%

    td, th {
      padding 5px 5px 5px 12px
      text-align left
      border-right 1px solid #D9D9D9;
      border-bottom 1px solid #EEEEEE;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 20px;
    }

    td:last-child, th:last-child {
      border-right none;
    }

    thead {
      background: #F0F5FF;
    }

    th {
      color: rgba(0, 0, 0, 0.8);
      font-weight bold
    }

    .summary {
      background: #F0F5FF;
    }

    .input {
      border 1px solid #ccc;
      display block
      line-height 2
      width calc(100% - 12px)
      border-radius 5px
      padding 0 10px
      box-sizing border-box
    }

    .input {
      outline none
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
